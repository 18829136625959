import {ChangeDetectionStrategy, Component} from '@angular/core';
import { GeneralHeaderComponent } from '../layout/header/components/general-header/general-header.component';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../layout/footer/footer.component';


@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [GeneralHeaderComponent, RouterOutlet, FooterComponent]
})
export class PagesComponent {

}
