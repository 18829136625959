import {ModuleWithProviders, NgModule} from '@angular/core';
import {RtTitlesConfig, RtTitlesConfigToken} from './symbols';
import {provideStates} from '@ngxs/store';
import {RtTitlesState} from './rt-titles.state';

@NgModule()
export class RtTitlesModule {
  static forRoot(config?: RtTitlesConfig): ModuleWithProviders<RtTitlesModule> {
    return {
      ngModule: RtTitlesModule,
      providers: [
        provideStates([RtTitlesState]),
        {
          provide: RtTitlesConfigToken,
          useValue: config,
        },
      ],
    };
  }
}
