<app-general-header></app-general-header>

<div class="b-page homepage">
  <section class="page-intro b-container">
    <div class="page-intro__description">
      <h1 class="b-page-title">Brain Health &amp; Longevity Management</h1>
      <p>
        We are pioneering an approach to brain longevity which quantifies brain health
        and aligns patients with personal treatment recommendations.
      </p>
      <div class="page-intro-button">
        <a class="b-button" (click)="gs.navigate()">Get Started</a>
      </div>
    </div>
    <div class="page-intro__brain">
      <div class="page-intro__carousel-wrapper">
        <!-- Hack to avoid duplicating the carousel when SSR is active. It excludes the carousel from server build. -->
        <!-- Github issue: https://github.com/vitalii-andriiovskyi/ngx-owl-carousel-o/issues/280 -->
        @if (isBrowser) {
          <owl-carousel-o
            [options]="regionsCarouselOptions"
            (translated)="onRegionsCarouselSlideChange($event)"
            #regionsCarousel
          >
            @for (region of brainRegions; track region?.id) {
              <ng-template carouselSlide [width]="getRegionsCarouselItemWidth(region)" [id]="region.id">
                <div
                  class="page-intro__brain-region"
                  [ngClass]="{'page-intro__brain-region--active': region.id === activeBrainRegion.id}"
                  (click)="selectCarouselRegion(region)"
                >
                  {{region.name}}
                </div>
              </ng-template>
            }
          </owl-carousel-o>
        }
      </div>


      @if (activeBrainRegion.name === 'Hippocampal Genetics') {
        <div class="page-intro__brain-image">
          <img [src]="activeBrainRegion.src" [srcset]="activeBrainRegion.srcSet" [alt]="activeBrainRegion.alt">
        </div>
        <div class="page-intro__brain-info">
          <p class="page-intro__your-info">
            Your Hippocampal Gene Score: <strong>{{activeBrainRegion.measured}}</strong>
          </p>
          <p>
            Population Average: <strong>{{activeBrainRegion.average}}</strong>
          </p>
          <p>
            Related Brain Functions: <strong>{{activeBrainRegion.functions}}</strong>
          </p>
        </div>
      } @else {
        <div class="page-intro__brain-image">
          <img [src]="activeBrainRegion.src" [srcset]="activeBrainRegion.srcSet" [alt]="activeBrainRegion.alt">
        </div>
        <div class="page-intro__brain-info">
          <p class="page-intro__your-info">
            Your {{activeBrainRegion.name}} is <strong>{{activeBrainRegion.measured}} cm<sup>3</sup></strong>
          </p>
          <p>
            Population Average: <strong>{{activeBrainRegion.average}} cm<sup>3</sup></strong>
          </p>
          <p>
            Related Brain Functions: <strong>{{activeBrainRegion.functions}}</strong>
          </p>
        </div>
      }

    </div>
  </section>

  <div class="b-page-wrapper--gray">
    <div class="prioritize-health b-container"
      [preRender]="false"
      (deferLoad)="showPrioritizeHealthImages = true"
      >
      <h3 class="homepage__header prioritize-health__header"> Two ways to start using BrainKey:</h3>

      <div class="prioritize-health__blocks">
        <div class="prioritize-health__block" (click)="gs.navigate('/dashboard/upload-scan', authTypes.UPLOAD_SCAN)">
          <div class="prioritize-health__image">
            @if (showPrioritizeHealthImages) {
              <img src="/assets/images/home/cd-rom.svg" alt="">
            }
          </div>
          <h3>Brain Scan Data</h3>
          <p>
            Analyze your MRI scans to learn about your brain health and longevity.
          </p>
        </div>

        <div class="prioritize-health__block" (click)="gs.navigate('/dashboard/', authTypes.GENETICS, {mode: 'genes'})">
          <div class="prioritize-health__image">
            <img src="/assets/images/home/genetic.svg" alt="">
          </div>
          <h3>Genetic Data</h3>
          <p>
            Analyze your genetic data to discover what your genes predict about your brain longevity.
          </p>
        </div>

        <!--        <div class="prioritize-health__block" routerLink="retrieve-mri">-->
        <!--          <div class="prioritize-health__image">-->
        <!--            <img *ngIf="showPrioritizeHealthImages" src="/assets/images/home/share.svg" alt="">-->
      <!--          </div>-->
      <!--          <h3>Request Scans</h3>-->
      <!--          <p>-->
      <!--            Request previous scans and share your records-->
    <!--          </p>-->
  <!--        </div>-->
</div>
</div>
</div>

<section class="brain-key-report b-container"
  [preRender]="false"
  (deferLoad)="showBrainKeyReportImage = true"
  >
  <div class="brain-key-report__content">
    <div class="brain-key-report__block">
      <h3 class="homepage__header">With BrainKey you get</h3>
      <p class="homepage__text">
        3D Visualizations, brain volume and genetic measurements, plus personalized recommendations for nutrition,
        exercise, and lifestyle changes.
      </p>
      <a class="homepage__link" (click)="gs.navigate()">
        <div>
          Learn More
        </div>
        <div>
          <img src="/assets/images/arrow-right--blue.svg" alt="">
        </div>
      </a>
    </div>
  </div>
  @if (showBrainKeyReportImage) {
    <div class="brain-key-report__image">
      <div class="large-image">
        <img src="/assets/images/home/large/brain-key-report-1x.webp?v=1"
          alt="brain-key-report brain regions"
             srcset="/assets/images/home/large/brain-key-report-1x.webp?v=1 1x,
                     /assets/images/home/large/brain-key-report-2x.webp?v=1 2x,
                     /assets/images/home/large/brain-key-report-3x.webp?v=1 3x,
                     /assets/images/home/large/brain-key-report-4x.webp?v=1 4x">
        </div>
        <div class="standard-image">
          <img src="/assets/images/home/standard/brain-key-report-1x.webp?v=1"
            alt="brain-key-report brain regions"
             srcset="/assets/images/home/standard/brain-key-report-1x.webp?v=1 1x,
                     /assets/images/home/standard/brain-key-report-2x.webp?v=1 2x,
                     /assets/images/home/standard/brain-key-report-3x.webp?v=1 3x,
                     /assets/images/home/standard/brain-key-report-4x.webp?v=1 4x">
          </div>
          <div class="tablet-image">
            <img src="/assets/images/home/tablet/brain-key-report-1x.webp?v=1"
              alt="brain-key-report brain regions"
             srcset="/assets/images/home/tablet/brain-key-report-1x.webp?v=1 1x,
                     /assets/images/home/tablet/brain-key-report-2x.webp?v=1 2x,
                     /assets/images/home/tablet/brain-key-report-3x.webp?v=1 3x,
                     /assets/images/home/tablet/brain-key-report-4x.webp?v=1 4x">
            </div>
            <div class="mobile-image">
              <img src="/assets/images/home/mobile/brain-key-report-1x.webp?v=1"
                alt="brain-key-report brain regions"
             srcset="/assets/images/home/mobile/brain-key-report-1x.webp?v=1 1x,
                     /assets/images/home/mobile/brain-key-report-2x.webp?v=1 2x,
                     /assets/images/home/mobile/brain-key-report-3x.webp?v=1 3x,
                     /assets/images/home/mobile/brain-key-report-4x.webp?v=1 4x">
              </div>
            </div>
          }
        </section>

        <div class="b-page-wrapper--gray">
          <section class="explore b-container"
            [preRender]="false"
            (deferLoad)="showExploreImage = true"
            >
          @if (showExploreImage) {
            <div class="explore__image">
              <div class="large-image">
                <img src="/assets/images/home/large/explore-phone-1x.webp?v=4"
                  alt="explore brain regions"
               srcset="/assets/images/home/large/explore-phone-1x.webp?v=4 1x,
                     /assets/images/home/large/explore-phone-2x.webp?v=4 2x,
                     /assets/images/home/large/explore-phone-3x.webp?v=4 3x,
                     /assets/images/home/large/explore-phone-4x.webp?v=4 4x">
                </div>
                <div class="standard-image">
                  <img src="/assets/images/home/standard/explore-phone-1x.webp?v=4"
                    alt="explore brain regions"
               srcset="/assets/images/home/standard/explore-phone-1x.webp?v=4 1x,
                     /assets/images/home/standard/explore-phone-2x.webp?v=4 2x,
                     /assets/images/home/standard/explore-phone-3x.webp?v=4 3x,
                     /assets/images/home/standard/explore-phone-4x.webp?v=4 4x">
                  </div>
                  <div class="tablet-image">
                    <img src="/assets/images/home/tablet/explore-phone-1x.webp?v=4"
                      alt="explore brain regions"
               srcset="/assets/images/home/tablet/explore-phone-1x.webp?v=4 1x,
                     /assets/images/home/tablet/explore-phone-2x.webp?v=4 2x,
                     /assets/images/home/tablet/explore-phone-3x.webp?v=4 3x,
                     /assets/images/home/tablet/explore-phone-4x.webp?v=4 4x">
                    </div>
                    <div class="mobile-image">
                      <img src="/assets/images/home/mobile/explore-phone-1x.webp?v=4"
                        alt="explore brain regions"
               srcset="/assets/images/home/mobile/explore-phone-1x.webp?v=4 1x,
                     /assets/images/home/mobile/explore-phone-2x.webp?v=4 2x,
                     /assets/images/home/mobile/explore-phone-3x.webp?v=4 3x,
                     /assets/images/home/mobile/explore-phone-4x.webp?v=4 4x">
                      </div>
                    </div>
                  }
                  <div class="explore__content">
                    <div class="explore__block">
                      <h3 class="homepage__header">Explore over 25 of your brain regions in 3D</h3>
                      <p class="homepage__text">
                        Get an inside look at your brain and navigate it in 3D. Use BrainKey to visualize
                        MRI scans in a way never before possible.
                      </p>
                      <a class="homepage__link" (click)="gs.navigate()">
                        <div>
                          Start Exploring
                        </div>
                        <div>
                          <img src="/assets/images/arrow-right--blue.svg" alt="">
                        </div>
                      </a>
                    </div>
                  </div>
                </section>
              </div>

              <section class="measurements b-container"
                [preRender]="false"
                (deferLoad)="showMeasurementsImage = true"
                >
              <div class="measurements__content">
                <h3 class="homepage__header">Track and compare your brain over time</h3>
                <p class="homepage__text">
                  Accurately measure and track the volume of each brain structure automatically.
                </p>
                <a class="homepage__link" (click)="gs.navigate()">
                  <div>
                    Access Metrics
                  </div>
                  <div>
                    <img src="/assets/images/arrow-right--blue.svg" alt="">
                  </div>
                </a>
              </div>
              @if (showMeasurementsImage) {
                <div class="measurements__image">
                  <div class="large-image">
                    <img src="/assets/images/home/large/graph-statistic-1x.webp?v=4"
                      alt="brain measurements"
             srcset="/assets/images/home/large/graph-statistic-1x.webp?v=4 1x,
                       /assets/images/home/large/graph-statistic-2x.webp?v=4 2x,
                       /assets/images/home/large/graph-statistic-3x.webp?v=4 3x,
                       /assets/images/home/large/graph-statistic-4x.webp?v=4 4x">
                    </div>
                    <div class="standard-image">
                      <img src="/assets/images/home/standard/graph-statistic-1x.webp?v=4"
                        alt="brain measurements"
             srcset="/assets/images/home/standard/graph-statistic-1x.webp?v=4 1x,
                       /assets/images/home/standard/graph-statistic-2x.webp?v=4 2x,
                       /assets/images/home/standard/graph-statistic-3x.webp?v=4 3x,
                       /assets/images/home/standard/graph-statistic-4x.webp?v=4 4x">
                      </div>
                      <div class="tablet-image">
                        <img src="/assets/images/home/tablet/graph-statistic-1x.webp?v=4"
                          alt="brain measurements"
             srcset="/assets/images/home/tablet/graph-statistic-1x.webp?v=4 1x,
                       /assets/images/home/tablet/graph-statistic-2x.webp?v=4 2x,
                       /assets/images/home/tablet/graph-statistic-3x.webp?v=4 3x,
                       /assets/images/home/tablet/graph-statistic-4x.webp?v=4 4x">
                        </div>
                        <div class="mobile-image">
                          <img src="/assets/images/home/mobile/graph-statistic-1x.webp?v=4"
                            alt="brain measurements"
             srcset="/assets/images/home/mobile/graph-statistic-1x.webp?v=4 1x,
                       /assets/images/home/mobile/graph-statistic-2x.webp?v=4 2x,
                       /assets/images/home/mobile/graph-statistic-3x.webp?v=4 3x,
                       /assets/images/home/mobile/graph-statistic-4x.webp?v=4 4x">
                          </div>
                        </div>
                      }
                    </section>

                    <div class="b-page-wrapper--gray">
                      <section class="insights b-container"
                        [preRender]="false"
                        (deferLoad)="showInsightsImage = true"
                        >
                      @if (showInsightsImage) {
                        <div class="insights__image">
                          <div class="large-image">
                            <img src="/assets/images/home/large/insights-phone-1x.webp?v=4"
                              alt="recommendations and insights"
               srcset="/assets/images/home/large/insights-phone-1x.webp?v=4 1x,
                     /assets/images/home/large/insights-phone-2x.webp?v=4 2x,
                     /assets/images/home/large/insights-phone-3x.webp?v=4 3x,
                     /assets/images/home/large/insights-phone-4x.webp?v=4 4x">
                            </div>
                            <div class="standard-image">
                              <img src="/assets/images/home/standard/insights-phone-1x.webp?v=4"
                                alt="recommendations and insights"
               srcset="/assets/images/home/standard/insights-phone-1x.webp?v=4 1x,
                     /assets/images/home/standard/insights-phone-2x.webp?v=4 2x,
                     /assets/images/home/standard/insights-phone-3x.webp?v=4 3x,
                     /assets/images/home/standard/insights-phone-4x.webp?v=4 4x">
                              </div>
                              <div class="tablet-image">
                                <img src="/assets/images/home/tablet/insights-phone-1x.webp?v=4"
                                  alt="recommendations and insights"
               srcset="/assets/images/home/tablet/insights-phone-1x.webp?v=4 1x,
                     /assets/images/home/tablet/insights-phone-2x.webp?v=4 2x,
                     /assets/images/home/tablet/insights-phone-3x.webp?v=4 3x,
                     /assets/images/home/tablet/insights-phone-4x.webp?v=4 4x">
                                </div>
                                <div class="mobile-image">
                                  <img src="/assets/images/home/mobile/insights-phone-1x.webp?v=4"
                                    alt="recommendations and insights"
               srcset="/assets/images/home/mobile/insights-phone-1x.webp?v=4 1x,
                     /assets/images/home/mobile/insights-phone-2x.webp?v=4 2x,
                     /assets/images/home/mobile/insights-phone-3x.webp?v=4 3x,
                     /assets/images/home/mobile/insights-phone-4x.webp?v=4 4x">
                                  </div>
                                </div>
                              }
                              <div class="insights__content">
                                <h3 class="homepage__header">Get actionable recommendations and insights</h3>
                                <p class="homepage__text">
                                  BrainKey uses your brain data to deliver personalized recommendations and insights.
                                </p>
                                <a class="homepage__link" (click)="gs.navigate()">
                                  <div>
                                    Review Insights
                                  </div>
                                  <div>
                                    <img src="/assets/images/arrow-right--blue.svg" alt="">
                                  </div>
                                </a>
                              </div>
                            </section>
                          </div>

                          <section class="brain-age b-container"
                            [preRender]="false"
                            (deferLoad)="showBrainAgeImage = true"
                            >
                          <div class="brain-age__content">
                            <h3 class="homepage__header">Calculate your BrainAge</h3>
                            <p class="homepage__text">
                              Use the latest neuroscience research to calculate how your brain age compares with your actual age.
                            </p>
                            <a class="homepage__link" (click)="gs.navigate()">
                              <div>
                                Get Started
                              </div>
                              <div>
                                <img src="/assets/images/arrow-right--blue.svg" alt="">
                              </div>
                            </a>
                          </div>
                          @if (showBrainAgeImage) {
                            <div class="brain-age__image">
                              <div class="large-image">
                                <img src="/assets/images/home/large/brain-age-phone-1x.webp?v=4"
                                  alt="calculate brainage"
             srcset="/assets/images/home/large/brain-age-phone-1x.webp?v=4 1x,
                      /assets/images/home/large/brain-age-phone-2x.webp?v=4 2x,
                      /assets/images/home/large/brain-age-phone-3x.webp?v=4 3x,
                      /assets/images/home/large/brain-age-phone-4x.webp?v=4 4x">
                                </div>
                                <div class="standard-image">
                                  <img src="/assets/images/home/standard/brain-age-phone-1x.webp?v=4"
                                    alt="calculate brainage"
             srcset="/assets/images/home/standard/brain-age-phone-1x.webp?v=4 1x,
                      /assets/images/home/standard/brain-age-phone-2x.webp?v=4 2x,
                      /assets/images/home/standard/brain-age-phone-3x.webp?v=4 3x,
                      /assets/images/home/standard/brain-age-phone-4x.webp?v=4 4x">
                                  </div>
                                  <div class="tablet-image">
                                    <img src="/assets/images/home/tablet/brain-age-phone-1x.webp?v=4"
                                      alt="calculate brainage"
             srcset="/assets/images/home/tablet/brain-age-phone-1x.webp?v=4 1x,
                      /assets/images/home/tablet/brain-age-phone-2x.webp?v=4 2x,
                      /assets/images/home/tablet/brain-age-phone-3x.webp?v=4 3x,
                      /assets/images/home/tablet/brain-age-phone-4x.webp?v=4 4x">
                                    </div>
                                    <div class="mobile-image">
                                      <img src="/assets/images/home/mobile/brain-age-phone-1x.webp?v=4"
                                        alt="calculate brainage"
             srcset="/assets/images/home/mobile/brain-age-phone-1x.webp?v=4 1x,
                      /assets/images/home/mobile/brain-age-phone-2x.webp?v=4 2x,
                      /assets/images/home/mobile/brain-age-phone-3x.webp?v=4 3x,
                      /assets/images/home/mobile/brain-age-phone-4x.webp?v=4 4x">
                                      </div>
                                    </div>
                                  }
                                </section>

                                <div class="b-page-wrapper--gray">
                                  <section class="genetics b-container"
                                    [preRender]="false"
                                    (deferLoad)="showBrainAgeImage = true"
                                    >
                                  <div class="genetics__image">
                                    <div class="large-image">
                                      <img src="/assets/images/home/large/genes-impact-1x.webp"
                                        alt="genetics"
               srcset="/assets/images/home/large/genes-impact-1x.webp 1x,
                      /assets/images/home/large/genes-impact-2x.webp 2x,
                      /assets/images/home/large/genes-impact-3x.webp 3x,
                      /assets/images/home/large/genes-impact-4x.webp 4x">
                                      </div>
                                      <div class="standard-image">
                                        <img src="/assets/images/home/standard/genes-impact-1x.webp"
                                          alt="genetics"
               srcset="/assets/images/home/standard/genes-impact-1x.webp 1x,
                      /assets/images/home/standard/genes-impact-2x.webp 2x,
                      /assets/images/home/standard/genes-impact-3x.webp 3x,
                      /assets/images/home/standard/genes-impact-4x.webp 4x">
                                        </div>
                                        <div class="tablet-image">
                                          <img src="/assets/images/home/tablet/genes-impact-1x.webp"
                                            alt="genetics"
               srcset="/assets/images/home/tablet/genes-impact-1x.webp 1x,
                      /assets/images/home/tablet/genes-impact-2x.webp 2x,
                      /assets/images/home/tablet/genes-impact-3x.webp 3x,
                      /assets/images/home/tablet/genes-impact-4x.webp 4x">
                                          </div>
                                          <div class="mobile-image">
                                            <img src="/assets/images/home/mobile/genes-impact-1x.webp"
                                              alt="genetics"
               srcset="/assets/images/home/mobile/genes-impact-1x.webp 1x,
                      /assets/images/home/mobile/genes-impact-2x.webp 2x,
                      /assets/images/home/mobile/genes-impact-3x.webp 3x,
                      /assets/images/home/mobile/genes-impact-4x.webp 4x">
                                            </div>
                                          </div>
                                          <div class="genetics__content">
                                            <h3 class="homepage__header">Learn how your genes impact your brain health</h3>
                                            <p class="homepage__text">
                                              Bring your personalized genetics results from companies like 23andMe, AncestryDNA, or MyHeritage to BrainKey
                                              and let us tell you what your genes say about your brain!
                                            </p>
                                            <a class="homepage__link" (click)="gs.navigate('/dashboard/', authTypes.UPLOAD_SCAN, {'mode': 'genes'})">
                                              <div>
                                                Upload my data
                                              </div>
                                              <div>
                                                <img src="/assets/images/arrow-right--blue.svg" alt="">
                                              </div>
                                            </a>
                                          </div>
                                        </section>
                                      </div>

                                      <div class="quote b-container">
                                        <div class="d-flex align-items-center align-items-sm-start">
                                          <div class="quote__image">
                                            <img src="/assets/images/about-brainkey/team/Owen-Philips.webp" alt="">
                                          </div>
                                          <div class="quote__author--mobile d-block d-sm-none">
                                            <div class="quote__name">
                                              Owen Philips, PhD
                                            </div>
                                            <div class="quote__position">
                                              Neuroscientist<br>Founder & CEO
                                            </div>
                                            <div class="quote__socials">
                                              <div class="quote__social">
                                                <a href="https://www.linkedin.com/in/owenrobertphillips/" target="_blank">
                                                  <img src="/assets/images/socials/linkedin-icon--black.svg" alt="">
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="quote__text">
                                          <p>
                                            “My own mother has had a treatable version of dementia that should have been identified and treated years ago.
                                            But it was missed, and her outcome is worse for it.
                                          </p>
                                          <p>
                                            We created BrainKey to create a powerful new tool that can fundamentally improve how we identify, treat, and
                                            manage brain health. It’s a personal mission for me, but it’s a global problem as 1 in 3 will experience
                                            dementia in their lifetime.”
                                          </p>

                                          <div class="quote__author d-none d-sm-flex">
                                            <div class="quote__name">
                                              -Owen Philips, PhD
                                            </div>
                                            <div class="quote__position">
                                              CEO and Founder of BrainKey
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div class="b-page-wrapper--gray">
                                        <section class="stories b-container">
                                          <h2>BrainKey Stories</h2>

                                          <div class="stories__carousel">
                                            <app-stories-carousel class="flex-1"></app-stories-carousel>
                                          </div>
                                        </section>
                                      </div>

                                      <section class="get-started">
                                        <h2>Ready to explore your brain?</h2>
                                        <a class="b-button" (click)="gs.navigate()">Get Started</a>
                                      </section>

                                      <app-footer></app-footer>
                                    </div>

                                    <!-- Required to allow region change on mobile scroll -->
                                    <div class="sticky-fix"></div>
