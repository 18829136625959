import {NgModule} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {DeferLoadModule} from '@trademe/ng-defer-load';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {LayoutModule} from '../layout/layout.module';
import {StoriesCarouselComponent} from './stories-carousel/stories-carousel.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    NgxPageScrollModule,
    RouterModule,
    DeferLoadModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    CarouselModule,
    StoriesCarouselComponent,
  ],
  exports: [StoriesCarouselComponent],
})
export class HomeModule {}
