import {Subject, Subscription, timer} from 'rxjs';
import {delay, startWith, switchMap} from 'rxjs/operators';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {CarouselComponent, CarouselModule, OwlOptions} from 'ngx-owl-carousel-o';
import {CarouselService} from 'ngx-owl-carousel-o/lib/services/carousel.service';
import {IdleService} from '../layout/services/idle.service';
import {GetStartedService} from '../layout/header/get-started.service';
import {WINDOW} from '../universal/window/window.service';
import {HomepageRegionsCarouselItem} from './models';
import {RtPlatformService} from '../rt-platform/rt-platform.service';
import {LoginSignupService} from '../layout/header/login-signup.service';
import {AuthorizationType} from '../auth/symbols';
import {LayoutModule} from '../layout/layout.module';
import {NgClass} from '@angular/common';
import {DeferLoadModule} from '@trademe/ng-defer-load';
import {HomeModule} from './home.module';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LayoutModule, CarouselModule, NgClass, DeferLoadModule, HomeModule],
  standalone: true,
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly isBrowser = this.platform.isBrowser;

  @ViewChild('regionsCarousel') regionsCarousel: CarouselComponent;

  showPrioritizeHealthImages = false;
  showBrainKeyReportImage = false;
  showExploreImage = false;
  showMeasurementsImage = false;
  showInsightsImage = false;
  showBrainAgeImage = false;
  authTypes = AuthorizationType;

  private subscription = new Subscription();

  private resetCarouselScrollTimer$ = new Subject<void>();

  regionsCarouselOptions: OwlOptions = {
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    nav: false,
    autoWidth: true,
    responsive: {
      0: {
        loop: true,
        center: true,
      },
    },
  };

  brainRegions: HomepageRegionsCarouselItem[] = [
    {
      id: '1',
      name: 'Hippocampal Genetics',
      measured: 0.75,
      average: 0,
      functions: 'memory & learning',
      src: '/assets/images/home/brain-sections/hippocampal-genetic-1x.webp',
      srcSet: `/assets/images/home/brain-sections/hippocampal-genetic-1x.webp 1x,
               /assets/images/home/brain-sections/hippocampal-genetic-2x.webp 2x,
               /assets/images/home/brain-sections/hippocampal-genetic-3x.webp 3x,
               /assets/images/home/brain-sections/hippocampal-genetic-4x.webp 4x`,
      alt: 'hippocampal-genetic',
      labelWidthDict: {
        large: 234,
        standard: 195,
        tablet: 152,
        mobile: 238,
      },
    },
    {
      id: '2',
      name: 'Hippocampus',
      measured: 6.32,
      average: 6.02,
      functions: 'memories and learning',
      src: '/assets/images/home/brain-sections/hippocampus-1x.webp',
      srcSet: `/assets/images/home/brain-sections/hippocampus-1x.webp 1x,
               /assets/images/home/brain-sections/hippocampus-2x.webp 2x,
               /assets/images/home/brain-sections/hippocampus-3x.webp 3x,
               /assets/images/home/brain-sections/hippocampus-4x.webp 4x`,
      alt: 'hippocampus',
      labelWidthDict: {
        large: 163,
        standard: 137,
        tablet: 108,
        mobile: 167,
      },
    },
    {
      id: '3',
      name: 'Cerebellum',
      measured: 141.43,
      average: 147.29,
      functions: 'coordinate body movement',
      src: '/assets/images/home/brain-sections/cerebellum-1x.webp',
      srcSet: `/assets/images/home/brain-sections/cerebellum-1x.webp 1x,
               /assets/images/home/brain-sections/cerebellum-2x.webp 2x,
               /assets/images/home/brain-sections/cerebellum-3x.webp 3x,
               /assets/images/home/brain-sections/cerebellum-4x.webp 4x`,
      alt: 'cerebellum',
      labelWidthDict: {
        large: 142,
        standard: 121,
        tablet: 95,
        mobile: 147,
      },
    },
    {
      id: '4',
      name: 'Amygdala',
      measured: 2.48,
      average: 3.12,
      functions: 'emotions and motivation',
      src: '/assets/images/home/brain-sections/amygdala-1x.webp',
      srcSet: `/assets/images/home/brain-sections/amygdala-1x.webp 1x,
               /assets/images/home/brain-sections/amygdala-2x.webp 2x,
               /assets/images/home/brain-sections/amygdala-3x.webp 3x,
               /assets/images/home/brain-sections/amygdala-4x.webp 4x`,
      alt: 'amygdala',
      labelWidthDict: {
        large: 131,
        standard: 111,
        tablet: 88,
        mobile: 135,
      },
    },
    {
      id: '5',
      name: 'Caudate Nucleus',
      measured: 8.61,
      average: 10.29,
      functions: 'language comprehension, hearing',
      src: '/assets/images/home/brain-sections/caudate-nucleus-1x.webp',
      srcSet: `/assets/images/home/brain-sections/caudate-nucleus-1x.webp 1x,
               /assets/images/home/brain-sections/caudate-nucleus-2x.webp 2x,
               /assets/images/home/brain-sections/caudate-nucleus-3x.webp 3x,
               /assets/images/home/brain-sections/caudate-nucleus-4x.webp 4x`,
      alt: 'caudate nucleus',
      labelWidthDict: {
        large: 188,
        standard: 157,
        tablet: 123,
        mobile: 192,
      },
    },
    {
      id: '6',
      name: 'Frontal Lobe',
      measured: 239.46,
      average: 248.29,
      functions: 'memory, attention, motivation',
      src: '/assets/images/home/brain-sections/frontal-lobe-1x.webp',
      srcSet: `/assets/images/home/brain-sections/frontal-lobe-1x.webp 1x,
               /assets/images/home/brain-sections/frontal-lobe-2x.webp 2x,
               /assets/images/home/brain-sections/frontal-lobe-3x.webp 3x,
               /assets/images/home/brain-sections/frontal-lobe-4x.webp 4x`,
      alt: 'frontal lobe',
      labelWidthDict: {
        large: 152,
        standard: 128,
        tablet: 101,
        mobile: 156,
      },
    },
    {
      id: '7',
      name: 'Brainstem',
      measured: 36.27,
      average: 34.29,
      functions: 'heart rate & breathing',
      src: '/assets/images/home/brain-sections/brainstem-1x.webp',
      srcSet: `/assets/images/home/brain-sections/brainstem-1x.webp 1x,
               /assets/images/home/brain-sections/brainstem-2x.webp 2x,
               /assets/images/home/brain-sections/brainstem-3x.webp 3x,
               /assets/images/home/brain-sections/brainstem-4x.webp 4x`,
      alt: 'brainstem',
      labelWidthDict: {
        large: 133,
        standard: 113,
        tablet: 89,
        mobile: 137,
      },
    },
  ];

  activeBrainRegion = this.brainRegions[0];

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateActiveRegionOnScroll();
  }

  // Required for carousel to update after resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resizeRegionsCarousel();
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private title: Title,
    private meta: Meta,
    private idle: IdleService,
    private cd: ChangeDetectorRef,
    public gs: GetStartedService,
    private platform: RtPlatformService,
    public ls: LoginSignupService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.idle.ready$.pipe(delay(2000)).subscribe(() => {
        if (!this.showPrioritizeHealthImages) {
          this.showPrioritizeHealthImages = true;
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.idle.ready$.pipe(delay(3000)).subscribe(() => {
        if (!this.showExploreImage) {
          this.showExploreImage = true;
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.idle.ready$.pipe(delay(4000)).subscribe(() => {
        if (!this.showMeasurementsImage) {
          this.showMeasurementsImage = true;
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.idle.ready$.pipe(delay(5000)).subscribe(() => {
        if (!this.showInsightsImage) {
          this.showInsightsImage = true;
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.idle.ready$.pipe(delay(6000)).subscribe(() => {
        if (!this.showBrainAgeImage) {
          this.showBrainAgeImage = true;
          this.cd.detectChanges();
        }
      })
    );

    if (this.platform.isBrowser) {
      this.subscription.add(
        this.resetCarouselScrollTimer$
          .pipe(
            startWith(undefined as void),
            switchMap(() => timer(1500, 1500))
          )
          .subscribe(() => {
            if (this.window.innerWidth > 576) {
              this.regionsCarousel.next();
            }
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.resizeRegionsCarousel();
  }

  resizeRegionsCarousel(): void {
    const windowWidth = this.window.innerWidth;
    const anyCarousel = this.regionsCarousel as any;

    if (anyCarousel?.carouselService) {
      const carouselService = anyCarousel.carouselService as CarouselService;
      carouselService.onResize(windowWidth);
    }
  }

  getRegionsCarouselItemWidth(region: HomepageRegionsCarouselItem) {
    const windowWidth = this.window.innerWidth;

    if (windowWidth > 1200) {
      return region.labelWidthDict.large;
    } else if (windowWidth > 992) {
      return region.labelWidthDict.standard;
    } else if (windowWidth > 576) {
      return region.labelWidthDict.tablet;
    } else {
      return region.labelWidthDict.mobile;
    }
  }

  selectCarouselRegion(region: HomepageRegionsCarouselItem) {
    this.regionsCarousel.to(region.id);
    this.resizeRegionsCarousel();
    this.cd.detectChanges();
  }

  selectNextRegion() {
    if (this.activeBrainRegion === this.brainRegions[this.brainRegions.length - 1]) {
      this.activeBrainRegion = this.brainRegions[0];
    } else {
      this.activeBrainRegion = this.brainRegions[+this.activeBrainRegion.id];
    }
    this.resetCarouselScrollTimer$.next();
    this.cd.detectChanges();
  }

  onRegionsCarouselSlideChange(event) {
    const seekedElement = event.slides.find(el => el.center === true) || event.slides[0];
    const nextRegion = this.brainRegions.find(item => item.id === seekedElement.id);

    if (nextRegion) {
      this.activeBrainRegion = nextRegion;
      this.resetCarouselScrollTimer$.next();
      this.cd.detectChanges();
    }
  }

  updateActiveRegionOnScroll() {
    const windowWidth = this.window.innerWidth;
    const currentScroll = this.window.pageYOffset;
    const offsetStep = 100;
    const sectionPadding = 200;

    if (windowWidth <= 576) {
      let newActiveRegion = this.brainRegions[0];

      for (let i = 0; i < this.brainRegions.length; i++) {
        if (currentScroll > sectionPadding + offsetStep * i) {
          newActiveRegion = this.brainRegions[i];
        }
      }

      this.regionsCarousel.to(newActiveRegion.id);
      this.cd.detectChanges();
    }
  }
}
