import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import {Store} from '@ngxs/store';
import {CookieService} from 'ngx-cookie-service';
import {CookiesState} from '../cookies.state';
import {CookiesModuleConfig, CookiesModuleConfigToken} from '../symbols';
import { AsyncPipe } from '@angular/common';


@Component({
    selector: 'app-cookies-popup',
    templateUrl: './cookies-popup.component.html',
    styleUrls: ['./cookies-popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterLink, AsyncPipe],
})
export class CookiesPopupComponent implements OnInit {
  public readonly isGdprCountry$: Observable<boolean> = this.store.select(CookiesState.isGdprCountry);

  /** Contain a key that might be used to retrieve data if a user accepts cookies. */
  private cookieAgreedKey = this.moduleConfig.COOKIES_AGREED_KEY;

  /** Stream that indicates if cookie modal should be displayed. */
  public display$: Observable<boolean>;

  /** List of routes where the cookie modal should not appear. */
  public readonly excludedRoutes = this.moduleConfig.EXCLUDE_ROUTES_LIST;

  /** Source for applying data to display modal toggle. */
  public readonly agreedSource = new BehaviorSubject<boolean>(!!this.cookieService.get(this.cookieAgreedKey));

  /** Indicates if user accepted the cookies. */
  public readonly agreed$ = this.agreedSource.asObservable();

  constructor(
    private cookieService: CookieService,
    private router: Router,
    @Inject(CookiesModuleConfigToken) private moduleConfig: CookiesModuleConfig,
    private store: Store
  ) {}

  ngOnInit(): void {
    // Set display stream to true if user is not accepted cookies, is located in GDPR country and is not on excluded route.
    this.display$ = combineLatest([this.isGdprCountry$, this.agreed$]).pipe(
      // The cookie modal should be displayed if a user is in the country where acceptance is required, and cookies are not accepted yet.
      map(([isGdpr, agreed]) => {
        return isGdpr && !agreed;
      }),

      // Routes black list matching is a complicated operation, so check it only if other conditions not met.
      switchMap(shouldDisplay => {
        if (shouldDisplay && !!this.excludedRoutes.length) {
          return this.router.events.pipe(
            // Extract only finished navigation event.
            filter(event => event instanceof NavigationEnd),

            // Check if current url matches any of provided excluded route.
            map(routerEvent => {
              const currentUrl = (routerEvent as NavigationEnd).url;
              return !this.excludedRoutes.some(route => currentUrl.includes(route));
            })
          );
        }

        // Previous condition met, modal should not be displayed anyway.
        return of(shouldDisplay);
      })
    );
  }

  /**
   * Hide cookie modal window and save answer in cookies.
   */
  public iAgree(): void {
    this.cookieService.set(this.cookieAgreedKey, 'yes', 10000);
    this.agreedSource.next(true);
  }
}
