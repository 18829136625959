import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import { CarouselComponent, OwlOptions, CarouselModule } from 'ngx-owl-carousel-o';
import {WINDOW} from '../../universal/window/window.service';
import {RtPlatformService} from '../../rt-platform/rt-platform.service';


interface CarouselContentItem {
  name: string;
  since: number;
  text: string;
}


@Component({
    selector: 'app-stories-carousel',
    templateUrl: './stories-carousel.component.html',
    styleUrls: ['./stories-carousel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CarouselModule]
})
export class StoriesCarouselComponent implements OnInit {
  @ViewChild('storiesCarousel') storiesCarousel: CarouselComponent;

  carouselItems: CarouselContentItem[] = [
    {
      name: 'Kierstin, New Jersey',
      since: 2020,
      text: `Finding BrainKey was an unexpected surprise. I love to see the structure of my own brain and the
        possibility of tracking changes over time.`
    },
    {
      name: 'Doug, San Francisco',
      since: 2020,
      text: `Seeing and exploring my own brain in Brainkey is awesome! It inspired me to read up on the structure
        of the brain, which led me to research more health topics and now I have the benefit and satisfaction
        of a greater understanding of the body. BrainKey's automatic region segmentation is especially cool,
        and way beyond anything I've seen in other software.`
    },
    {
      name: 'Jonathan, Chicago',
      since: 2020,
      text: `Brainkey made it easy for someone with no imaging experience to approach to understand what I am looking at.<br><br>
        Amazing and easy-to-use technology, definitely worth doing.`
    },
    {
      name: 'Nicole, Idaho',
      since: 2020,
      text: `When I first tried BrainKey, I was really excited to see my brain in 3D. It’s so much better than
        looking at a 2D scan.I like how I can easily flip back and forth to different views. BrainKey is
        really helping me to learn about my MRI brain scans.`
    },
    {
      name: 'Ren, Los Angeles',
      since: 2020,
      text: `It was amazing to interact with my brain and get a full report of my brain health. The visualization is
        like nothing I’ve seen before with a very easy-to-use interface. Overall, BrainKey greatly helped form my own
        understanding of my brain health as I approach an age where I could be potentially more vulnerable to
        neurodegenerative diseases.`
    },
    {
      name: 'Alex, San Francisco',
      since: 2020,
      text: `I am excited to get a BrainKey Scan to open this ‘black box’ of my head and gain deeper
        self-understanding and actionable recommendations. As someone with chronic and complex conditions,
        understanding brain health is a vital step toward my recovery. Brainkey opens up my world and
        empowers me to have more control over something so fundamental to my life as my brain.`
    },
    {
      name: 'Luisa, Zurich, Switzerland',
      since: 2020,
      text: `In the past year, my mom had multiple brain scans in one hospital. Whenever I wanted to go and talk
        to a different doctor about her care at a different hospital, they needed the scans. I had to go
        back to the hospital, get a new CD, and send it via mail to the other doctor. BrainKey solves this
        problem. I have her data stored online and when I see a new doctor, it’s right there at my fingertips.`
    },
    {
      name: 'Jonathan, Cleveland',
      since: 2020,
      text: `I have a unique brain and ever since my brain surgery, I have been fascinated to learn
        more about it. The problem with CT-scans and MRI scans is that they only show 2 dimensions.
        I would highly recommend BrainKey to anyone interested in their brain and seeing it in 3D!
        I believe their technology is amazing.`
    },
    {
      name: 'Amelia, Los Angeles',
      since: 2020,
      text: `I have always been intrigued about seeing my own brain. Being given the opportunity to voluntarily
        check in on my brain health is so unique. My scanning experience was extremely safe, especially during
        COVID-19, I felt very comfortable. It was so interesting to see the health insights provided by
        BrainKey. I especially liked zooming in on different parts of my brain and exploring them.`
    },
    {
      name: 'Will, San Francisco',
      since: 2020,
      text: `Without any symptoms that might lead a doctor to refer me to get a brain MRI, I felt like BrainKey offered
        something that I couldn’t get elsewhere. The process of the scan itself was seamless; the imaging center
        employees were very helpful in making the process comfortable and safe. It was exciting to get the email
        from BrainKey following the scan and gain access to my brain on my computer at home, like I’ve never
        been able to before. `
    },
    {
      name: 'Ömer, Amsterdam',
      since: 2020,
      text: `Laying eyes on your own brain for the first time is kind of a surreal experience. Brain imaging software
        used to be reserved for medical professionals only. But BrainKey has managed to provide a simple and intuitive
        interface to interact with your own brain so that anyone can easily travel through all of the nooks and crannies
        of their own brain. The data they provide allows you to get to know your brain better than ever before and even
        learn a thing or two about the brain regions while doing so.`
    },
    {
      name: 'Y. L., San Francisco',
      since: 2021,
      text: `Fast service with reasonable price. I would like to do this annually. Cannot believe we track height/weight/eye...
       monthly and annually. But not the most important organ, the brain.`
    },
  ];

  /** Slides split into chunks to display few items on single page as one carousel item. */
  formedSlidesData: CarouselContentItem[][];

  activeItemIndex = 0;

  customOptions: OwlOptions = {
    loop: true,
    stagePadding: 0,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    nav: false,
    responsive: {
      0: {
        items: 1,
        center: true,
      },
      640: {
        items: 1,
        center: false,
      },
      990: {
        items: 1,
        center: true
      },
    }
  };

  /**
   * Required for carousel to dynamically form carousel content.
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    // Update slides data on every screen size change.
    this.formSlidesData(window.innerWidth);
  }

  constructor(
    @Inject(WINDOW) private window,
    private platform: RtPlatformService,
    protected cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    if (this.platform.isBrowser) {
      this.formSlidesData(window.innerWidth);
    }
  }

  onSlideChange(event) {
    this.activeItemIndex = event.startPosition;
    this.cd.detectChanges();
  }

  /**
   * Split slides into less chunk to display them as single page of carousel.
   * Number of items on single page depends on screen size:
   * - up to 640: 1 item;
   * - up to 990: 2 items;
   * - 990 and more: 3 items.
   */
  formSlidesData(windowWidth: number): void {
    // Count number of items for single page depends on screen width.
    let itemsOnSinglePage = 3;
    if (windowWidth < 640) {
      itemsOnSinglePage = 1;
    } else if (windowWidth < 990) {
      itemsOnSinglePage = 2;
    }

    /** Split all available carousel items to lesser chunks. */
    const splitToChunks = this.carouselItems.reduce((resultArray: CarouselContentItem[][], item: CarouselContentItem, index) => {
      // Calculate chunk index for item (for example, 5[item]/2[onPage] = 2.5 = 2[number of chuck]).
      const chunkIndex = Math.floor(index / itemsOnSinglePage);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // Start a new chunk.
      }
      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    // If last chunk not fully filled => fill empty space with placeholders.
    if (splitToChunks.length) {
      const lastChunkIdx = splitToChunks.length - 1;
      if (splitToChunks[lastChunkIdx].length !== itemsOnSinglePage) {
        const needEmptyItems = itemsOnSinglePage - splitToChunks[lastChunkIdx].length;
        for (let i = 0; i < needEmptyItems; i++) {
          splitToChunks[lastChunkIdx].push({name: '', since: 0, text: ''});
        }
      }
    }
    // Update local variable (not return list directly in function for optimization purposes).
    this.formedSlidesData = splitToChunks;
  }

}
