import {Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, RouterModule, RouterOutlet} from '@angular/router';
import {AfterViewInit, ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from './auth/services/auth/auth.service';
import {DocumentClickHandler} from './shared/classes/document-click-handler';
import {RtTrackUserEventsService} from './rt-track-user-events/rt-track-user-events.service';
import {RtPlatformService} from './rt-platform/rt-platform.service';
import {CookiesModule} from './shared/microservices/cookies/cookies.module';
const {version} = require('../../package.json');


@Component({
  selector: 'app-component',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterOutlet, CookiesModule],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions = new Subscription();

  version = version;

  constructor(
    @Inject(DOCUMENT) private document,
    private route: ActivatedRoute,
    private auth: AuthService,
    private trackUserEvents: RtTrackUserEventsService,
    private platform: RtPlatformService
  ) {
    if (this.platform.isBrowser) {
      /** Used for Google Tag Manager (track navigation between pages). */
      this.trackUserEvents.initializeNavigationTracking();
    }
  }

  ngOnInit() {
    this.subscriptions.add(this.route.queryParams.subscribe(params => this.auth.saveInviteCode(params['invite'])));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    const el = this.document.getElementById('global-spinner');
    if (el) {
      el.style['display'] = 'none';
    }
  }

  @HostListener('document:click', ['$event'])
  outsideClick(e) {
    DocumentClickHandler.globalClickHandler(e);
  }
}
