import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {provideStates} from '@ngxs/store';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {CookiesPopupComponent} from './cookies-popup/cookies-popup.component';
import {CookiesState} from './cookies.state';
import {CookiesModuleConfig, CookiesModuleConfigToken, defaultCookiesModuleConfig} from './symbols';
import {RtPlatformModule} from '../../../rt-platform/rt-platform.module';

@NgModule({
  imports: [CommonModule, RtPlatformModule, RouterModule, NgxPageScrollModule, CookiesPopupComponent],
  exports: [CookiesPopupComponent],
})
export class CookiesModule {
  static forRoot(config?: CookiesModuleConfig): ModuleWithProviders<CookiesModule> {
    return {
      ngModule: CookiesModule,
      providers: [
        provideStates([CookiesState]),
        {
          provide: CookiesModuleConfigToken,
          useValue: {...defaultCookiesModuleConfig, ...config},
        },
      ],
    };
  }
}
