{
  "name": "brain-key-app",
  "version": "",
  "private": true,
  "repository": {
    "type": "git",
    "url": "git+https://github.com/BrainKey/brainkey-api"
  },
  "bugs": {
    "url": "https://github.com/BrainKey/brainkey-api/issues"
  },
  "scripts": {
    "ng": "ng",
    "conventional-changelog": "conventional-changelog",
    "start": "ng serve --proxy-config proxy.conf.json",
    "start:ssr": "ng serve --proxy-config proxy.conf.json --configuration=local-ssr",
    "start:staging": "ng serve --proxy-config proxy.conf.staging.json",
    "build": "ng build --configuration=production",
    "build:production": "ng build --configuration=production",
    "build:staging": "ng build --configuration=staging",
    "build:develop": "ng build --configuration=develop",
    "build:local": "ng build --configuration=local",
    "build:local-ssr": "ng build --configuration=local-ssr",
    "serve:ssr": "node dist/brain-key-app/server/server.mjs",
    "test": "ng test",
    "test:coverage": "rimraf coverage && npm run test -- --code-coverage",
    "lint": "ng lint",
    "lint:fix": "ng lint ngx-admin-demo --fix",
    "lint:styles": "stylelint ./src/**/*.scss",
    "lint:ci": "npm run lint && npm run lint:styles",
    "e2e": "cypress open --config-file cypress/configs/cypress.config.local.ts",
    "e2e:dev": "cypress run --config-file cypress/configs/cypress.config.dev.ts",
    "e2e:dev:open": "cypress open --config-file cypress/configs/cypress.config.dev.ts",
    "e2e:stag": "cypress run --config-file cypress/configs/cypress.config.stag.ts",
    "e2e:stag:open": "cypress open --config-file cypress/configs/cypress.config.stag.ts",
    "e2e:prod": "cypress run --config-file cypress/configs/cypress.config.prod.ts",
    "e2e:prod:open": "cypress open --config-file cypress/configs/cypress.config.prod.ts",
    "docs": "compodoc -p tsconfig.app.json -d docs",
    "docs:serve": "compodoc -p tsconfig.app.json -d docs -s",
    "release:changelog": "npm run conventional-changelog -- -p angular -i CHANGELOG.md -s"
  },
  "dependencies": {
    "@almothafar/angular-signature-pad": "^5.0.1",
    "@angular/animations": "^19.1.0",
    "@angular/cdk": "^19.1.0",
    "@angular/common": "^19.1.0",
    "@angular/compiler": "^19.1.0",
    "@angular/core": "^19.1.0",
    "@angular/forms": "^19.1.0",
    "@angular/google-maps": "^19.1.0",
    "@angular/material": "^19.1.0",
    "@angular/material-moment-adapter": "^19.1.0",
    "@angular/platform-browser": "^19.1.0",
    "@angular/platform-browser-dynamic": "^19.1.0",
    "@angular/platform-server": "^19.1.0",
    "@angular/router": "^19.1.0",
    "@angular/ssr": "^19.1.0",
    "@angular/youtube-player": "^19.0.5",
    "@bugsnag/js": "^7.1.0",
    "@egjs/hammerjs": "^2.0.17",
    "@ngxs/devtools-plugin": "^19.0.0",
    "@ngxs/logger-plugin": "^19.0.0",
    "@ngxs/router-plugin": "^19.0.0",
    "@ngxs/storage-plugin": "^19.0.0",
    "@ngxs/store": "^19.0.0",
    "@popperjs/core": "^2.9.2",
    "@sentry/angular-ivy": "^7.109.0",
    "@trademe/ng-defer-load": "^14.1.0",
    "angularx-qrcode": "^19.0.0",
    "angularx-social-login": "^4.1.0",
    "bootstrap": "5.1.3",
    "date-fns": "^2.14.0",
    "domino": "^2.1.5",
    "echarts": "^5.6.0",
    "echarts-gl": "^2.0.9",
    "eslint-utils": "^3.0.0",
    "express": "^4.17.1",
    "font-awesome": "^4.7.0",
    "fs-extra": "^10.0.0",
    "hammerjs": "^2.0.8",
    "intersection-observer": "^0.12.2",
    "intl": "1.2.5",
    "lodash": "^4.17.21",
    "luxon": "^3.4.4",
    "moment": "^2.29.1",
    "moment-timezone": "^0.5.33",
    "ng2-file-upload": "^5.0.0",
    "ngx-cookie-service": "^11.0.2",
    "ngx-device-detector": "^2.1.1",
    "ngx-echarts": "^19.0.0",
    "ngx-image-cropper": "^7.2.1",
    "ngx-material-timepicker": "^13.1.1",
    "ngx-owl-carousel-o": "^19.0.0",
    "ngx-page-scroll": "^12.0.0",
    "ngx-page-scroll-core": "^12.0.0",
    "normalize.css": "8.0.1",
    "primeicons": "^6.0.1",
    "primeng": "^17.13.0",
    "roboto-fontface": "0.10.0",
    "rxjs": "^7.8.0",
    "socicon": "3.0.5",
    "spark-md5": "^3.0.2",
    "ts-loader": "8.0.11",
    "tslib": "^2.0.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "axios": "^1.7.9",
    "@angular-devkit/build-angular": "^19.1.0",
    "@angular-eslint/builder": "^19.0.2",
    "@angular-eslint/eslint-plugin": "^19.0.2",
    "@angular-eslint/eslint-plugin-template": "^19.0.2",
    "@angular-eslint/schematics": "^19.0.2",
    "@angular-eslint/template-parser": "^19.0.2",
    "@angular/cli": "^19.1.0",
    "@angular/compiler-cli": "^19.1.0",
    "@angular/language-service": "^19.1.0",
    "@compodoc/compodoc": "^1.1.11",
    "@eslint/eslintrc": "^3.2.0",
    "@eslint/js": "^9.18.0",
    "@juggle/resize-observer": "^3.3.1",
    "@types/cypress": "^1.1.3",
    "@types/express": "^4.17.21",
    "@types/googlemaps": "3.39.12",
    "@types/jasmine": "^4.6.4",
    "@types/jasminewd2": "~2.0.8",
    "@types/lodash": "^4.17.0",
    "@types/node": "~20.15.0",
    "@types/youtube": "^0.0.46",
    "@typescript-eslint/eslint-plugin": "^8.21.0",
    "@typescript-eslint/parser": "^8.21.0",
    "browser-sync": "^3.0.0",
    "codelyzer": "^6.0.0",
    "conventional-changelog-cli": "2.1.1",
    "cypress": "^13.8.0",
    "cypress-file-upload": "^5.0.8",
    "eslint": "^9.18.0",
    "eslint-config-prettier": "8.5.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-jsdoc": "^48.2.4",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "eslint-plugin-prettier": "^5.1.3",
    "gulp": "^4.0.2",
    "gulp-purifycss": "^0.2.0",
    "husky": "4.3.0",
    "jasmine-core": "^4.1.0",
    "jasmine-spec-reporter": "^7.0.0",
    "karma": "^6.3.19",
    "karma-chrome-launcher": "^3.1.1",
    "karma-cli": "^2.0.0",
    "karma-coverage-istanbul-reporter": "^3.0.3",
    "karma-jasmine": "^5.0.0",
    "karma-jasmine-html-reporter": "^1.7.0",
    "ng2-mock-component": "^0.2.0",
    "npm-run-all": "4.1.5",
    "prettier": "^2.8.0",
    "rimraf": "3.0.2",
    "stylelint": "13.8.0",
    "ts-node": "~9.0.0",
    "typescript": "5.7.3"
  }
}
